import { Puff } from "react-loader-spinner";
const LoaderComp = () => {
	return (
		<Puff
        visible={true}
        height="80"
        width="80"
        color="rgb(215, 112, 33)"
        ariaLabel="puff-loading"
        wrapperStyle={{}}
        wrapperClass=""
        />
	);
}; 
export default LoaderComp;
