import { useEffect, useState } from "react";
import Loader from "../../loader";
import emailjs from "@emailjs/browser";

function Contact() {
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => emailjs.init("9rj2_k6mwBzIWyDVa"), []);

  setTimeout(() => {
      setIsLoading(false);
  }, 1500);
  const [firstName, setFirstName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [lastName, setLastName] = useState('');
  const [value, setValue] = useState('');
  const [message, setMessage] = useState('');

  const handleChange=(event)=> {
    setValue(event.target.value);
  }
  const handleEmail=(event)=> {
    setEmail(event.target.value);
  }
  const handleFirstName=(event)=> {
    setFirstName(event.target.value);
  }
  const handleLastName=(event)=> {
    setLastName(event.target.value);
  }
  const handlePhone=(event)=> {
    setPhone(event.target.value);
  }
  const handleMessage=(event)=> {
    setMessage(event.target.value);
  }

  const handleSubmit=async(event)=> {
    event.preventDefault();
    var details = {"name" : firstName +" "+ lastName, "email" : email, "mobile": phone, "look": value, "query": message};
    
    const serviceId = "service_t4r3ewr";
    const templateId = "template_6950zz9"; try {
      await emailjs.send(serviceId, templateId, {
        "details": details,
      });
      alert("email successfully sent check inbox");
    } catch (error) {
      console.log(error);
    }
  }

return (
  <div>
  {isLoading ? (
      <div className='loader'>
        <Loader />
      </div>
      ) : (
  <div className="contactPage">
    <div className="contactDiv">
    <div className="contactLeft">
    <form action="https://formsubmit.co/contact@sabharwalassociates.in" onSubmit={handleSubmit}>
    <h1>Lets Get Started</h1> 
    <input
    type="text"
    onChange={handleFirstName}
    value={firstName}
    placeholder="Your First Name"
    />
    <input
    type="text"
    onChange={handleLastName}
    value={lastName}
    placeholder="Your Last Name"
    />
    <input
    type="text"
    onChange={handleEmail}
    value={email}
    placeholder="Email"
    />
    <input
    type="text"
    onChange={handlePhone}
    value={phone}
    placeholder="Phone Number"
    />
    <select value={value} onChange={handleChange}>
    <option disabled={true} value="">What are you Looking for?</option>
    <option value="buy">Looking for Buy</option>
    <option value="sell">Looking for Sell</option>
    <option value="rent">Looking for Rent</option>
    </select>
    <textarea rows="4" cols="50" type="text" onChange={handleMessage} value={message} id="message" placeholder="Tell us something..."></textarea>
    <input type="submit" id="submitButton" className="button-5" value="Submit" />
    </form>
    </div>
    <div className="contactRight">
      <h1>Contact Info</h1>
      <p>K-7/34, DLF City Phase 2</p>
      <p>Sec-25, Gurugram, Haryana</p>
      <p>+91 9891909114</p>
      <p>sabharwalassociates12@gmail.com</p>

    </div>
    </div>
  </div>)}
  </div>
);
}
export default Contact;