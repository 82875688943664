import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
} from 'react-router-dom';
import About from './Components/About/about';
import Contact from './Components/Contact/contact';
import Projects from './Components/Projects/projects';
import Home from './Components/Home/home';
import companyLogo from './logo.png';

function App() {
  return (
    <div className="App">
        <Router>
           <div className='nav'>
            <div className="links">
            <Link className="link" to="/projects">Projects</Link>
            <Link className="link" to="/about">About Us</Link>
            <Link className="link" to="/contact">Contact Us</Link> 
            </div>
            <div>
            <Link className="home" to="/"><img src={companyLogo} alt=""/></Link>
            </div>
           </div>
            <Routes>
              <Route exact path='/projects' element={<Projects />}></Route>
              <Route exact path='/about' element={<About /> }></Route>
              <Route exact path='/contact' element={<Contact /> }></Route>
              <Route exact path='/' element={<Home  /> }></Route>
            </Routes>
       </Router>
    </div>
  );
}

export default App;
