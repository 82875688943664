import {React, useState} from 'react';
import house from './house.jpg';
import Loader from "../../loader";
 
function About () {
  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => {
      setIsLoading(false);
  }, 1500);
    return <div>
      {isLoading ? (
      <div className='loader'>
        <Loader />
      </div>
      ) : (
        <div>
      <div className="homepage" style={{ backgroundImage: `url(${house})` }}></div>
        <div className='texthome'>
          <h1>ABOUT US</h1>
          <p>Sabharwal Associates is a real estate company, a kinship of professionals bound by the common belief in delivering the best value to its clients. A firm with 34 years of experience serving clients across India.</p>
        </div>
        <div className='story'>
          <h1>Our Story</h1>
          <p>Welcome to Sabharwal Associates, your trusted partner in real estate since 1989. For over three decades, we’ve been at the forefront of developing modern residential buildings and providing expert consulting services to those seeking homes in Gurgaon’s most coveted locations.
Founded by Mr. Arun Kumar Sabharwal, our company has been a beacon of reliability and excellence in the Gurgaon real estate scene. In the early 2000s, Mr. Varun Sabharwal, his son, seamlessly took over the reins, continuing our legacy with the same dedication and passion.
Throughout our journey, we’ve delivered thousands of dream homes, making us a household name in the industry. Our expertise extends to prime locations like DLF - I, II, III, IV, Sushant Lok - I, Gurgaon, South City - I, Suncity, and beyond.
In keeping up with the digital age, we’ve established a strong presence on online portals such as 99 Acres, Magicbricks, and Housing.com. You can also connect with us on social media platforms like Instagram and YouTube, where we share insights, updates, and glimpses into our portfolio and projects.
At Sabharwal Associates , we believe in not just building homes but crafting lifestyles. Whether you’re looking for your dream home or expert guidance in navigating Gurgaon’s real estate landscape, we’re here to make your journey seamless and rewarding.
Explore our website to learn more about our services, projects, and how we can turn your real estate dreams into reality.</p>
        </div>
        
      <div className='footer'>
        <div className='aboutFooter'>
          <h4>About Us</h4>
          <p>Sabharwal Associates is a real estate company, a kinship of professionals bound by the common belief in delivering the best value to its clients. A firm with 34 years of experience serving clients across India.</p>
        </div>
        <div className='contactFooter'>
          <h4>Get in Touch</h4>
          <p>K-7/34, DLF City Phase 2</p>
          <p>Sec-25, Gurugram, Haryana</p>
          <p>+91 9891909114</p>
          <p>sabharwalassociates12@gmail.com</p>
        </div>
        <div className='socialFooter'>
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
          <path fill="#039be5" d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"></path><path fill="#fff" d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z"></path>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
          <radialGradient id="yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1" cx="19.38" cy="42.035" r="44.899" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fd5"></stop><stop offset=".328" stop-color="#ff543f"></stop><stop offset=".348" stop-color="#fc5245"></stop><stop offset=".504" stop-color="#e64771"></stop><stop offset=".643" stop-color="#d53e91"></stop><stop offset=".761" stop-color="#cc39a4"></stop><stop offset=".841" stop-color="#c837ab"></stop></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path><radialGradient id="yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2" cx="11.786" cy="5.54" r="29.813" gradientTransform="matrix(1 0 0 .6663 0 1.849)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#4168c9"></stop><stop offset=".999" stop-color="#4168c9" stop-opacity="0"></stop></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path><path fill="#fff" d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5	s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z"></path><circle cx="31.5" cy="16.5" r="1.5" fill="#fff"></circle><path fill="#fff" d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12	C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z"></path>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
          <linearGradient id="_osn9zIN2f6RhTsY8WhY4a_5MQ0gPAYYx7a_gr1" x1="10.341" x2="40.798" y1="8.312" y2="38.769" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#2aa4f4"></stop><stop offset="1" stop-color="#007ad9"></stop></linearGradient><path fill="url(#_osn9zIN2f6RhTsY8WhY4a_5MQ0gPAYYx7a_gr1)" d="M46.105,11.02c-1.551,0.687-3.219,1.145-4.979,1.362c1.789-1.062,3.166-2.756,3.812-4.758	c-1.674,0.981-3.529,1.702-5.502,2.082C37.86,8.036,35.612,7,33.122,7c-4.783,0-8.661,3.843-8.661,8.582	c0,0.671,0.079,1.324,0.226,1.958c-7.196-0.361-13.579-3.782-17.849-8.974c-0.75,1.269-1.172,2.754-1.172,4.322	c0,2.979,1.525,5.602,3.851,7.147c-1.42-0.043-2.756-0.438-3.926-1.072c0,0.026,0,0.064,0,0.101c0,4.163,2.986,7.63,6.944,8.419	c-0.723,0.198-1.488,0.308-2.276,0.308c-0.559,0-1.104-0.063-1.632-0.158c1.102,3.402,4.299,5.889,8.087,5.963	c-2.964,2.298-6.697,3.674-10.756,3.674c-0.701,0-1.387-0.04-2.065-0.122C7.73,39.577,12.283,41,17.171,41	c15.927,0,24.641-13.079,24.641-24.426c0-0.372-0.012-0.742-0.029-1.108C43.483,14.265,44.948,12.751,46.105,11.02"></path>
          </svg>
        </div>
      </div>
        </div>
      )}
    </div>
}
export default About;